<template>
  <div>
    <!-- Content -->

    <!---------------------->
    <!---- Cypto Logos  ---->
    <!---------------------->
    
    <div style="text-align:center">
      <div style="width:58px;margin:0 auto;" key="logosLogo">
        <!-- <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 533.25 533.25"><title>Crypto Logos</title><path d="M112.13,140.12a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-39 -130)" style="fill:#f7931a;fill-rule:evenodd"/><polygon points="193.5 73.13 266.63 0 339.75 73.13 266.63 146.25 193.5 73.13 193.5 73.13" style="fill:#3d3d3d;fill-rule:evenodd"/><path d="M499.13,140.12a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-39 -130)" style="fill:#f7931a;fill-rule:evenodd"/><polygon points="0 266.63 73.13 193.5 146.25 266.63 73.13 339.75 0 266.63 0 266.63" style="fill:#3d3d3d;fill-rule:evenodd"/><polygon points="387 266.63 460.13 193.5 533.25 266.63 460.13 339.75 387 266.63 387 266.63" style="fill:#3d3d3d;fill-rule:evenodd"/><path d="M305.63,333.62a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-39 -130)" style="fill:#f7931a;fill-rule:evenodd"/><path d="M112.13,527.12a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-39 -130)" style="fill:#f7931a;fill-rule:evenodd"/><polygon points="193.5 460.13 266.63 387 339.75 460.13 266.63 533.25 193.5 460.13 193.5 460.13" style="fill:#3d3d3d;fill-rule:evenodd"/><path d="M499.13,527.12a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-39 -130)" style="fill:#f7931a;fill-rule:evenodd"/></svg> -->
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.7 51.7"><title>Crypto Logos Logo</title><path d="M317.78,373.93a25.84,25.84,0,1,1-34.86,11,25.85,25.85,0,0,1,34.86-11h0Z" transform="translate(-280 -371)" style="fill:#f93;fill-rule:evenodd"/><path d="M315.86,406a13.68,13.68,0,0,1-7.7,4.2l-1.2,5.41-3.5-.78,1-4.51a13.63,13.63,0,0,1-3.52-.85l-1,4.57-3.5-.78,1.24-5.61A13.59,13.59,0,0,1,303,383.56l1.24-5.61,3.5,0.78-1,4.57a13.62,13.62,0,0,1,3.55.72l1-4.51,3.5,0.78-1.2,5.41a13.54,13.54,0,0,1,3.7,3.86,12.94,12.94,0,0,1,1.51,3.21l-5.63,1.78a8.46,8.46,0,0,0-.84-1.83A7.7,7.7,0,1,0,310,403.35a8.22,8.22,0,0,0,1.54-1.3l4.34,4h0Z" transform="translate(-280 -371)" style="fill:#fff"/></svg>
      </div>

      <div style="clear:both;height:11px;"></div>

      <span class="slogan-desktop">
        <h2 key="logosSlogan">High Quality Cryptocurrency Logos</h2>
        <span key="logosSlogan2">
          Download transparent .PNG and vector .SVG logo files
        </span>
      </span>

      <span class="slogan-mobile">
        <h2>High Quality<br>Cryptocurrency Logos</h2>
        <span>
          Download transparent .PNG and<br>vector .SVG logo files
        </span>
      </span>
    </div>
       

      <div style="clear:both;height:35px;"></div>

      <div class="search-div">
        <input class="search-input" type="text" v-model="search" placeholder="Search...">
      </div>

      <div style="clear:both;height:40px;"></div>


      <!-- REAL -->
      <div class="flex-grid">
        <div v-for="(coin_name, coin_id) in filteredItems" :key="coin_id" class="col">
          <div style="text-align:center;margin:0 auto;z-index:99999;margin-top:10px;">
            <router-link :to="'/'+coin_name">
              <div class="div-middle-outer"><div class="div-middle-inner"><img :src="'/logos/thumbs/'+coin_name+'.png' + version" class="div-middle-img" :alt="logos[coin_name].name + ' (' + logos[coin_name].symbol + ') Logo Thumb'"></div></div><div class="div-middle-text">{{ logos[coin_name].name }} ({{ logos[coin_name].symbol }}) logo</div>
            </router-link>
          </div>
        </div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
      </div>
      <div style="clear:both;"></div>


      <!-- experiments -->
      <!-- <div class="flex-grid">
        <div v-for="(coin_name, coin_id) in filteredItems" :key="coin_id" class="col">
          <div v-if="coin_name == 'sushiswap' || coin_name == 'veil'">
            <div class="div-middle-outer"><div class="div-middle-inner"><img :src="'/logos/'+coin_name+'-'+logos[coin_name].symbol.toLowerCase()+'-logo.png' + version" class="div-middle-img" :alt="logos[coin_name].name + ' (' + logos[coin_name].symbol + ') Logo Thumb'" style="padding-top:13px;"></div></div>
          </div>
          <div v-else style="text-align:center;margin:0 auto;z-index:99999;margin-top:10px;">
            <div class="div-middle-outer"><div class="div-middle-inner"><img :src="'/logos/'+coin_name+'-'+logos[coin_name].symbol.toLowerCase()+'-logo.svg' + version" class="div-middle-img" :alt="logos[coin_name].name + ' (' + logos[coin_name].symbol + ') Logo Thumb'"></div></div>
          </div>
        </div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
        <div class="col col-empty"></div>
      </div>
      <div style="clear:both;"></div> -->


      <!-- <div style="height:60px;"></div>
      <div style="text-align: center;">
        <h2>Poster</h2>
        <a href="https://cryptoclothing.cc/crypto-fashion/crypto-logos-poster" target="_blank"><img src="img/crypto-logos-poster-black.jpg?v=001" class="poster-image"></a>
        <div style="height:5px;"></div>
        <a href="https://cryptoclothing.cc/crypto-fashion/crypto-logos-poster" target="_blank"><button id="subs-button">Order Now</button></a>
        <div id="feedback"></div>
      </div> -->


      <div style="height:90px;"></div>
      <div style="text-align: center;">
        <h2>Updates</h2>
        <p>Join our mailing list to be informed about new logos and other relevant news</p>
        <div style="height:10px;"></div>
        <span id="submit-component">
        <input type="text" id="submit-input" placeholder="Enter your e-mail">
        <span class="focus-border">
        </span>
        <div style="height:15px;"></div>
        <button id="subs-button" @click="subscribeButton()">Subscribe</button>
        </span>
        <div id="feedback"></div>
      </div>
      <div style="height:30px;"></div>

    </div>

</template>

<script>

export default {
  components: {
    
  },
  data: function () {
    return {
      version: '?v=033',

      search: '',
      homePath: homePath,
      view: '',
      coins: {},
      digs: [],
      termsError: false,

      shippingNameSurname: '',
      shippingAddressLine1: '',
      shippingAddressLine2: '',
      shippingCountry: '',
      shippingEmail1: '',
      shippingEmail2: '',
      shippingPhone: '',

      // shippingNameSurname: 'aaa',
      // shippingAddressLine1: 'aaa',
      // shippingAddressLine2: 'aaa',
      // shippingCountry: 'LT',
      // shippingEmail1: 'aaa@aaa.aaa',
      // shippingEmail2: 'aaa@aaa.aaa',
      // shippingPhone: '111',

      shippingErrors: '',
      products: null,
      paymentProcessing: false,
      inner: '',
      complement: '',
      payWith: 'card',
      isSwitched: false,
    }
  },

  computed: {
    prodLabel() {
      if (this.digs.length > 1) { return "Products" }
      else { return "Product" }
    },
    productsPrice() {
      let price = 0;
      this.digs.forEach(function(element) {
        price += element.item_price * element.item_amount;
      });
      return price;
    },
    shipping() {
      // return this.digs.length + 7;
      let amount = 0;
      this.digs.forEach(function(element) {
        amount += +element.item_amount*2;
      });
      amount += 4;
      return amount;
    },
    finalSum() {
      return this.productsPrice + this.shipping;
    },
    filteredItems() {
      return Object.keys(cc_coins)
      .filter(key => {
        return key.toLowerCase().includes(this.search.toLowerCase()) || cc_coins[key].symbol.toLowerCase().includes(this.search.toLowerCase());
      })
    },

  },

  // watch: {     
  //   $route (to, from){
  //     if (to.name == 'CryptoLogos') {
  //       this.view = 'cryptoLogos';
  //       this.inner = "Crypto Logos";
  //       this.complement = "Cryptocurrency Logo Files (.SVG & .PNG) Download";
  //       this.$emit('updateHead');
  //     }
  //     else if (to.name == 'Home') {
  //       this.view = 'cryptoClothing';
  //       this.inner =  "Crypto Merchandise Store for Your Coin or Token";
  //       this.complement = "Crypto Logos";
  //       this.$emit('updateHead');
  //     }
  //   }
  // },

  // head: {
  //   title: function () {
  //     return {
  //       // title: this.title
  //       inner: this.inner,
  //       separator: '-',
  //       complement: this.complement
  //     }
  //   }
  // },

  methods: {
    log: function () {
      console.log(this.filteredItems);
    },
    toTop: function() {
      document.body.scrollTop = 0;
    },

    
    subscribeButton: function() {
      // var homeUrl = 'http://localhost';
      var homeUrl = 'https://cryptologos.cc';

      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
          if(xhr.response) {
              var resp = xhr.response;
              if (resp == "email error") {
              document.getElementById("feedback").innerHTML = "<span style='color:red;'><br>Error: Invalid e-mail address</span><br>";
              }
              else if (resp == "limit error") {
              document.getElementById("feedback").innerHTML = "<span style='color:red;'><br>You are doing that too much. Please wait and try again.<br>";
              }
              else if (resp == "success") {
              document.getElementById("submit-component").style.display = 'none';
              document.getElementById("feedback").innerHTML = "<b style='color:#ab00aa;font-size:16px;'>Subscribed!</b>";
              }
          }
          }
          else console.log('The request failed!');
      }
      var email = document.getElementById('submit-input').value;
      xhr.open('POST', homeUrl+'/mailing-list/_cl/server.php?action=mailing_list&data='+email);
      xhr.send();
    },
  },

  beforeMount() {
    this.logos = cc_coins;
  },

  mounted() {
    // if (this.$route.name == 'CryptoLogos') {
    //   this.inner = "Crypto Logos";
    //   this.complement = "Cryptocurrency Logo Files (.SVG & .PNG) Download";
    //   this.$emit('updateHead');
    // }
    // if (this.$route.name == 'CryptoLogos') {
    //   this.view = 'cryptoLogos'
    // }


    // Mailing-list
    var submitInput = document.getElementById("submit-input");
    submitInput.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.subscribeButton();
      }
    });

  },

};
</script>

<style>
input::-webkit-input-placeholder {
    font-size: 14px;
}


.slider-block {
  display:inline;
  vertical-align:middle;
}

.slider-block-label {
  width: 60px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #ccc; */
  background-color: #6A6A6A;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  /* background-color: #2196F3; */
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #2196F3; */
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input-basic {
  font-size:14px;
  height: 24px;
  margin-bottom:8px;
}

/* input[type=text] {
  font-size:13px;
} */

.button-payment-disabled, .button-payment-disabled:hover {
  padding: 10px 15px 10px 15px!important;
  background-color: #818181!important;
  cursor: default!important;
}




/* stripe checkout - Vue component
============================================ */

.payment-container {
  border: 2px solid #e0e0e0;
  padding: 15px;
  padding-top: 25px;
  padding-bottom: 35px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  background-color: white;
  line-height: 130%;
  float: left;
}

.payment-inner {
  max-width: 300px;
}

.payment-box {
  clear:both;
  margin-top:96px;
}

.switch-mobile {
  display: none;
}

.slogan-mobile {
  display: none;
}

/* @media screen and (max-width:680px) {
  .payment-inner-card {
    clear:both;
    float: left!important;
    margin-top: 20px;
  }
  .payment-box {
    margin-top:5px;
  }
  .switch-mobile {
    clear:both;
    width: 300px;
    float: left;
    display: block;
    margin-top:30px;
    margin-bottom:-15px;
  }
  .switch-desktop {
    display: none;
  }
} */

@media screen and (max-width: 420px) {
  .slogan-mobile {
    display: block;
  }
  .slogan-desktop {
    display: none;
  }
}

.payment-inner input, select {
  font-family: 'Arial', 'Helvetica', sans-serif;
}

.payment-inner-address input {
  padding-left: 5px;
}

.cc-icons {
  float: left;
  margin-left: 8px;
}

.payment-inner-address {
  line-height: 30px;
}

.dotted-line {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 6px;
  border-bottom: 1px dotted #000000;
}

.input-basic {
  width: 280px;
}

.address-errors {
  color: red;
  font-family: 'Arial', 'Helvetica', sans-serif;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.header-fades {
  height: 140px;
}

/* @media screen and (max-width: 640px) {
  .header-fades {
    height: 150px;
  }
}

@media screen and (max-width: 540px) {
  .header-fades {
    height: 160px;
  }
}

@media screen and (max-width: 440px) {
  .header-fades {
    height: 180px;
  }
} */




  /* Mailing-list */

  #submit-input {
    border: 1px solid #777;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 250px;
    height: 29px;
    padding-left: 5px;
    text-align: center;
    outline: none;
    color: #666;
  }
  #subs-button {
    background-color: #45708d;
    padding: 5px;
    width: 160px;
    color: white;
    cursor: pointer;
    height: 28px;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    outline: none;
  }
  #subs-button:hover {
    background-color: #3e7eae;
  }

  .poster-image {
    max-width:360px;
    margin-top:-5px;
  }

  @media screen and (max-width: 460px) {
    .poster-image {
      max-width:100%;
      margin-top:-5px;
    }
  }




</style>