<template>
  <div class="footer site-footer">
    <div class="footer-content">

      <div class="footer-column">

        <span style="font-size:19px;color:#6f6f6f;">Crypto Logos</span>

        <div style="height:20px;"></div>
        
        <a href="/" class="hover-footer" style="margin-left:0px;">Home</a>

        <br><br>

        <router-link to="/history" class="hover-footer" style="margin-left:0px;">History</router-link>

        <br>

        <router-link to="/guides" class="hover-footer">Guides</router-link>

        <!-- <br>

        <a href="https://api.cryptologos.cc" target="_blank" class="hover-footer">API</a> -->

        <br>

        <router-link to="/faq" class="hover-footer">FAQ</router-link>

        <br>
        
        <router-link to="/about" class="hover-footer">About</router-link>

      </div>

      <div class="footer-column">

        <span style="font-size:19px;color:#6f6f6f;">Links</span>

        <div style="height:20px;"></div>
        <a href="https://cryptologos.cc/bitcoin">Bitcoin Logo</a>
        <br>
        <a href="https://cryptologos.cc/ethereum">Ethereum Logo</a>
        <br>
        <a href="https://cryptologos.cc/dogecoin">Dogecoin Logo</a>
        <br><br>
        <a href="https://coinwink.com" target="_blank">Bitcoin Price Alert</a>
        <br>
        <a href="https://multiavatar.com" target="_blank">Avatar Generator</a>
        <br>
        <a href="https://8020.in" target="_blank">80/20 Rule</a>
        <br>
        <a href="https://8020.app" target="_blank">80/20 App</a>
      </div>


<div class="footer-column">

<!-- <span style="font-size:19px;color:#6f6f6f;">Social</span> -->
<!-- <div style="height:18px;"></div> -->
    <div style="width:25px;float:left;">
          <a href="https://twitter.com/cryptologoscc" title="Twitter" target="_blank">
            <svg id="svg-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.11 124.11"><title>Twitter</title><path d="M199.05,335a62.05,62.05,0,1,0,62.05,62.05A62.05,62.05,0,0,0,199.05,335h0Zm46.81,39.49a35.82,35.82,0,0,1-10.3,2.82,18,18,0,0,0,7.88-9.92,35.93,35.93,0,0,1-11.39,4.35,17.94,17.94,0,0,0-30.55,16.35,50.9,50.9,0,0,1-37-18.74,18,18,0,0,0,5.55,23.94,17.87,17.87,0,0,1-8.12-2.24c0,0.07,0,.15,0,0.22a18,18,0,0,0,14.38,17.58,17.95,17.95,0,0,1-8.09.31A18,18,0,0,0,185,421.62,36.16,36.16,0,0,1,158.48,429,50.74,50.74,0,0,0,186,437.1c33,0,51-27.32,51-51q0-1.17-.05-2.32a36.45,36.45,0,0,0,8.94-9.28h0Z" transform="translate(-137 -335)" /></svg>
          </a>
        </div>

        
    <div style="width:25px;float:left;margin-left:15px;">
          <a href="https://github.com/coinwink/crypto-logos-cc" title="GitHub" target="_blank">
            <svg id="svg-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.11 124.11"><title>GitHub</title><path d="M411.45,335a62.06,62.06,0,0,0-19.61,120.94c3.1,0.57,4.24-1.35,4.24-3,0-1.47-.05-5.38-0.08-10.55-17.26,3.75-20.9-8.32-20.9-8.32-2.82-7.17-6.89-9.08-6.89-9.08-5.63-3.85.43-3.77,0.43-3.77,6.23,0.44,9.51,6.39,9.51,6.39,5.53,9.48,14.52,6.74,18.06,5.16,0.56-4,2.17-6.74,3.94-8.3-13.78-1.57-28.27-6.89-28.27-30.67a24,24,0,0,1,6.39-16.65c-0.64-1.57-2.77-7.88.61-16.42,0,0,5.21-1.67,17.06,6.36a58.79,58.79,0,0,1,31.07,0c11.85-8,17-6.36,17-6.36,3.39,8.54,1.26,14.85.62,16.42A23.94,23.94,0,0,1,451,393.81c0,23.84-14.51,29.08-28.33,30.62,2.22,1.91,4.21,5.7,4.21,11.49,0,8.29-.08,15-0.08,17,0,1.66,1.12,3.59,4.27,3A62.06,62.06,0,0,0,411.45,335h0Z" transform="translate(-349.4 -335)"></path></svg>
          </a>
        </div>
</div>
      <!-- <div style="height:20px;"></div>

      <div class="footer-desktop">

        <div style="float:left;font-size:12px;">
          <span v-if="typeof($route.params.coinId) != 'undefined'">
              <router-link to="/">Home</router-link>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://8020.in" target="_blank">80/20</a>
          </span>
          <span v-else>
            <a href="https://coinwink.com" target="_blank" class="underline">Crypto Alerts</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://8020.in" target="_blank">80/20</a>
          </span>
        </div>

        <div style="float:right;width:25px;margin-top:-1px;">
          <a href="https://twitter.com/cryptologoscc" title="Twitter" target="_blank">
            <svg id="svg-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.11 124.11"><title>Twitter</title><path d="M199.05,335a62.05,62.05,0,1,0,62.05,62.05A62.05,62.05,0,0,0,199.05,335h0Zm46.81,39.49a35.82,35.82,0,0,1-10.3,2.82,18,18,0,0,0,7.88-9.92,35.93,35.93,0,0,1-11.39,4.35,17.94,17.94,0,0,0-30.55,16.35,50.9,50.9,0,0,1-37-18.74,18,18,0,0,0,5.55,23.94,17.87,17.87,0,0,1-8.12-2.24c0,0.07,0,.15,0,0.22a18,18,0,0,0,14.38,17.58,17.95,17.95,0,0,1-8.09.31A18,18,0,0,0,185,421.62,36.16,36.16,0,0,1,158.48,429,50.74,50.74,0,0,0,186,437.1c33,0,51-27.32,51-51q0-1.17-.05-2.32a36.45,36.45,0,0,0,8.94-9.28h0Z" transform="translate(-137 -335)" /></svg>
          </a>
        </div>

      </div>

      <div class="footer-mobile">
        
        <div style="float:left;font-size:12px;">
          <span v-if="typeof($route.params.coinId) != 'undefined'">
              <router-link to="/">Home</router-link>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://8020.in" target="_blank">80/20</a>
          </span>
          <span v-else>
            <a href="https://coinwink.com" target="_blank" class="underline">Crypto Alerts</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://8020.in" target="_blank">80/20</a>
          </span>
        </div>

        <div style="float:right;width:25px;margin-top:-1px;">
          <a href="https://twitter.com/cryptologoscc" title="Twitter" target="_blank">
            <svg id="svg-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.11 124.11"><title>Twitter</title><path d="M199.05,335a62.05,62.05,0,1,0,62.05,62.05A62.05,62.05,0,0,0,199.05,335h0Zm46.81,39.49a35.82,35.82,0,0,1-10.3,2.82,18,18,0,0,0,7.88-9.92,35.93,35.93,0,0,1-11.39,4.35,17.94,17.94,0,0,0-30.55,16.35,50.9,50.9,0,0,1-37-18.74,18,18,0,0,0,5.55,23.94,17.87,17.87,0,0,1-8.12-2.24c0,0.07,0,.15,0,0.22a18,18,0,0,0,14.38,17.58,17.95,17.95,0,0,1-8.09.31A18,18,0,0,0,185,421.62,36.16,36.16,0,0,1,158.48,429,50.74,50.74,0,0,0,186,437.1c33,0,51-27.32,51-51q0-1.17-.05-2.32a36.45,36.45,0,0,0,8.94-9.28h0Z" transform="translate(-137 -335)" /></svg>
          </a>
        </div>
      </div> -->


      <div style="clear:both;height:40px;"></div>
    </div>
    
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data() {
    return {

    }
  },
  methods: {
    // menuClose: function () {
    //   this.$parent.menuOpen = false;
    // }
  },

  mounted() {
    console.log(this.$route.params.coinId);

    // if (this.$route.name == 'ShoppingCart') {
    //   this.linkTo = '';
    //   this.linkText = '';
    // }

    // if (this.$route.name == 'CryptoLogo') {
    //   this.linkTo = '/'+this.$route.params.coinId;
    //   this.linkText = this.$parent.coins[this.$route.params.coinId].name+' ('+this.$parent.coins[this.$route.params.coinId].symbol+') Clothing';
    // }
    // else {
    //   this.linkTo = '';
    //   this.linkText = '';
    // }

    // else if (this.$route.name == 'CryptoClothing') {
    //   this.linkTo = this.homePath+'crypto-logos/'+this.$route.params.coinId;
    //   this.linkText = this.$route.params.coinId+' logo';
    // }
  },

  watch:{

  } 
}
</script>

<style>
  /* .stick {
    color:rgb(122, 122, 122);
  }

  .footer-mobile {
    display: none;
  }

  @media screen and (max-width: 680px) {
    .footer-desktop {
      display: none;
    }
    .footer-mobile {
      display: block;
    }
  } */

  #svg-twitter {
    fill:#848688;
    fill-rule:evenodd;
  }

  #svg-twitter:hover {
    fill:#adaeaf;
    fill-rule:evenodd;
  }


  
.stick {
  color:#777;
}

.footer {
  width: 100%;
  /* border-top: 1px solid grey; */
  /* background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;  */
  /* background-blend-mode: multiply,multiply; */
  background-color:#333333;
  left: 0;
  /* padding-top: 15px;
  padding-bottom: 45px; */
  /* min-height: 50px; */
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  overflow: hidden;
  /* height: 200px; */
}

@media only screen and (max-width: 480px) {
  .footer-content {
    padding-left: 10px;
    /* padding-right: 15px; */
  }
}

.footer-content {
  /* height: 300px; */
  max-width: 638px;
  /* max-width: 720px; */
  margin: 0 auto;
}

.footer-column {
  margin-top: 45px;
  width: 212px;
  float: left;
  line-height: 180%;
}

.footer {
  font-size: 14px;
  color: rgb(226, 226, 226);
  padding: 10px;
  box-sizing: border-box;
  padding-top: 0px;
}

.footer a {
  font-size: 14px;
  color: rgb(226, 226, 226)!important;
  line-height:22px;
  border-bottom: 0px!important;
}

.footer a:hover {
  text-decoration: underline!important;
  
  /* color:rgb(236, 236, 236)!important; */
}

  .footer-social {
    float:right;
  }
</style>